import { adjustPrice } from '../utils/priceConverter';

const faceProceduresData = [
    {
        name: 'fractional-microneedling',
        price: '250 лв.',
        imageUrl: '/img/face-procedures/vivace-microneedling.jpg',
        shortDescription: 'Фракционен радиочестотен микронидлинг',
        descriptions: [
            'Иновативна процедура в областта на козметичните и дерматологични терапии, която се използва за безусловно подмладяване на кожата и решаване на редица, различни кожни проблеми',
            'Метода комбинира радиочестотна терапия с фракционен микронидлинг, като стимулира колагеновата продукция в дълбоките слоеве на кожата, за да се подобри нейната структура и тонус. По време на процедурата десетки позлатени игли проникват в кожата, като предават в дълбочина фракционна и радиочестотна енергия в  епидермиса и дермата, което отключва естествен процес на регенерация. Засилва се потока от растежни фактори, цитокини и други активни молекули. Синтезират се нови, здрави колагенови и еластинови влакна.',
            'Насочват се към и към себумните и потни жлези, инхибират възпалителните реакции и активират анти-възпалителната и регенерираща система.',
            'Процедурата микронидлинг е насочена към подмладяване на кожата на лицето и тялото, лечение на акне и пост-акне, включително белези, както и коригиране на различни кожни промени (стрии, пигменти, целулит). Изключителни резултати при хиперхидроза / обилно потене в различни зони на тялото. ',
            'Клинично доказани резултати – с над  50% по-висока ефективност от процедури провеждани с фракционни лазери.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Лице',
            '2. Шия',
            '3. Деколте',
            '4. Тяло',
            'ТРЕТИРА УСПЕШНО:',
            '1. Заличава линии, бръчки и различни видове белези - пост-акнеични и пост-оперативни',
            '2. Контур',
            '3. Лифтинг',
            '4. Еластоза',
            '5. Доброкачествени образувания',
            '6. Увредена от слънцето кожа',
            '7. Белези от акне и шарка',
            '8. Розацея и Купероза ',
            '9. Чуствително и видимо подмладява кожата още след първата процедура',
            '10. Уплътнява и стяга',
            '11. Намалява и премахва хиперхидрозата по тялото',
        ],
        faq: [
            'Колко процедури са необходими?',
            'Желаните резултати се постигат с 3 – 5 процедури с интервал от 1 месец, а ефектът се задържа повече от 5 години',
            'Кога не се прилага процедурата?',
            'Остри инфекции, кръвни заболявания, изгарания, увреждане на кожата, инкоагулация на кръвта, бременност и кърмене, колоидни белези, прием на хормони (кортикостероиди) и стимуланти на кръвосъсирването и онкологични заболявания.',
            'Каква е продължителността на процедурата?',
            'Продължителността на процедурата варира в зависимост от зоната , но обикновено е между 15 – 20 минути.',
            'Има ли възстановителен период?',
            'Да. След процедурата кожата ще е зачервена и може да се очакват леки отоци и леко излющване за няколко  дни.',
            'Болезнена ли е процедурата?',
            'Не. Провежда се с локална, не инжекционна анестезия.'
        ],
        prices: [
            'Лице – 250 лв.',
            'Шия и деколте - 250 лв.',
            'Околоочен контур - 150 лв.',
            'Стрии - зона - 200 лв.'
        ],
    },
    {
        name: 'electroporaciq',
        price: `${adjustPrice(100, 0)} лв.`,
        imageUrl: '/img/face-procedures/electroporaciq-1.jpg',
        shortDescription: 'Електропорация - Безиглена мезотерапия',
        descriptions: [
            'Електропорацията е високо ефективна алтернатива на традиционната инжекционна мезотерапия.',
            'Иновативен комплекс от плазмени и фракционни микротокове , генерирани от йонизацията , сублимират тъканите,  ргенерират, възстановяват и обновяват изцяло кожата.',
            'Иновативна система за доставяне на активни вещества до дълбоките слоеве на кожата. Най-ефективният метод за подмладяване на кожата, избелване, лифтинг, хидратация, корекция на мазна и акнеична кожа.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Деколте',
            '2. Лице',
            '3. Ръце',
            '4. Шия',
            'РЕШЕНИЯ НА ПРОБЛЕМИ:',
            'Бръчки,  Нарушен контур, Неравномерен тен, Отпускане на кожата, Пигментни петна, Фини линии',
            'ТРЕТИРА УСПЕШНО:',
            '1. Хидратация и ревитализиране на кожата',
            '2. Отваряне на клетъчните канали, способстващи за усилване на метаболизма в клетките и отговорни за подмладяване на кожата',
            '3. Стимулиране регенерацията на нови клетки',
            '4. Изглаждане на възрастови и мимически бръчки',
            '5. Корекция на мазна и акнеична кожа',
            '6. Розацея и капиляри',
            '7. Акне и белези от него',
            '8. Пигментация и хиперпигментация на кожата',
            '9. Видимо подмладява зрялата кожа'
        ],
        faq: [
            'В кои случаи се препоръчва процедурата?',
            'Еелектропорацията се използва за  хидратация и ревитализиране на кожата,  отваряне на клетъчните канали, способстващи за усилване на метаболизма в клетките и отговорни за подмладяване на кожата,  стимулиране регенерацията на нови клетки,  изглаждане на възрастови и мимически бръчки,  корекция на мазна и акнеична кожа, розацея и капиляри, акне и белези от него, пигментация и хиперпигментация на кожата, подмладяване на  зряла кожа.',
            'Какви са предимствата на тази процедура?',
            'Изключително ремоделиране на кожата, неинвазивно заличаване на бръчки и несъвършенства по кожата, не  агресивна и високо ефективна.',
            'Какво да очакваме по време на процедурата?',
            'Процедурата не е болезнена и не се налага използването на обезболяващи.',
            'Какво да очакваме след процедурата?',
            'Кожата става стегната, хидратирана, с кристален тен и изключително блестяща.',
            'Какви грижи трябва да полагаме след такава процедура?',
            'Необходимо е използването на правилна домашна грижа.',
            'Необходим брой процедури?',
            'Всеки случай носи своите индивидуални характеристики, но са необходими не по-малко от 7 процедури',
            'Кога не се прилага процедурата?',
            'Бременност и кърмене, кожни инфекции и злокачествени образувания'
        ],
        prices: ['Цена за 1бр. процедура – 100 лв.',],
        // promos: [`Цена за 1бр. процедура – От 100 лв. на ${adjustPrice(100, 30)} лв.`,],
    },
    {
        name: 'carboxy',
        price: '125 лв.',
        imageUrl: '/img/face-procedures/carboxy-1.jpg',
        shortDescription: 'Карбокситерапия - лечение и подмладяване с въглероден диоксид',
        descriptions: [
            'КАРБОКСИТЕРАПИЯТА ПРЕДСТАВЛЯВА ПОДКОЖНО ВЪВЕЖДАНЕ НА ВЪГЛЕРОДЕНДИОКСИД, КОЙТО СЕ ИЗПОЛЗВА ЗА ПОДМЛАДЯВАНЕ И НЕХИРУРГИЧНО СТЯГАНЕ НА КОЖАТА, ЛЕЧЕНИЕ НА ЦЕЛУЛИТ, ЕЛИМИНИРАНЕ НА СТРИИ.',
            'КАРБОКСИТЕРАПИЯТА Е ЕДНА ОТ НАЙ-ЕФЕКТИВНИТЕ И БЕЗОПАСНИ ПРОЦЕДУРИ В СЪВРЕМЕННАТА КОЗМЕТОЛОГИЯ.',
            'Същността на метода: с помощта на специален апарат и тънка игла се инжектира медицински въглероден диоксид под кожата (или интрадермално), което моментално предизвиква вазодилатация и рязко увеличаване на местното кръвообращение.',
            'Процедурата по карбокситерапия се извършва на най-модерния апарат, който ви позволява да контролирате налягането, обема, дълбочината на инжектиране и концентрацията.',
            'Ефектът от карбокситерапията е най-ярко изразен при процедурите за подмладяване на кожата.',
            'Мощното активиране на кръвообращението след прием стимулира активността на фибробластите - клетки, които произвеждат градивния протеин на кожата, колаген. В резултат на това бръчките изчезват, кожата става еластична и се наблюдава изразен лифтинг ефект. След курс на карбокситерапия намаляването на повърхността на кожата може да достигне 15-20%, което е сравнимо с резултатите от пластичната хирургия!',
            'Карбокситерапията перфектно елиминира задръстванията, свързани с лошо кръвообращение. Тенът се подобрява, пигментацията намалява, синини под очите изчезват.',
            'В областта на долните клепачи използването на карбокситерапия дава невероятен резултат: кожата става плътна поради образуването на колаген и освен това намалява обемът на подкожната мастна тъкан (рамката на съединителната тъкан се „компресира“ ). Прословутите „торбички” под очите изчезват, а бръчките се изглаждат. В много случаи карбокситерапията може успешно да замести блефаропластиката.'
        ],
        faq: [
            'Каква е ефикасността на процедурата?',
            'Ефекта от карбокситерапията се вижда още при първата процедура, но за оптимален резултат се препоръчва курс от 3 до 5 процедури, като между всяка процедура трябва да има интервал от минимум 4-5 дни.'
        ],
        advantages: ['Регенерира и подмладява кожата', 'Намалва фините линии и бръчки', 'Минимизира порите', 'Премахва нежелания пигмент',],
        prices: ['Цена за 1бр. процедура – 150 лв.',],
        promos: ['Цена за 1бр. процедура – От 150 лв. на 125 лв.',],
    },
    {
        name: 'zalichavane-belezi',
        price: `${adjustPrice(120, 25)} лв.`,
        imageUrl: '/img/face-procedures/acne-1.jpg',
        shortDescription: 'Заличаване на белези от акне',
        descriptions: [
            'Стремежът ни към съвършенство е заложен в нас и за това от изключително значение за самочуствието ни е кристално чистата и красива кожа. Това е и причината непрестанно да търсим средства и методи за корекция на несъвършенствата.',
            'С революционната технология на  PLASMATEC и двата му специални режима на работа – плазмен и фракционен, се осъществява „изпаряване” на повърхностните епидермални слоеве и специфично топлинно въздействие върху дълбоките структури на кожата. За разлика от козметичните апарати ,по време на самата процедура не се наранява  кожата.',
            'Ефекта е пъти по-висок , активира се производството на нови здрави колагенови влакна, които изглаждат релефа на кожата, подобряват възстановяват нейната структура, плътност и еластичност.',
            'ТРЕТИРА УСПЕШНО:',
            '1. Фини линии и по-дълбоки бръчки',
            '2. Еластоза',
            '3. Доброкачествени образувания',
            '4. Увредена от слънцето кожа',
            '5. Белези от акне',
            '6. Белези от шарка',
            '7. Пост-оперативни белези и др.',
            '8. Лице, шия, деколте, гърба на дланите',
            '9. Видимо подмладява зрялата кожа',
            '10. Уплътнява и стяга',
            '11. Заличава линии, бръчки и различни видове белези – пост-акнеични, пост-оперативни и д.р.',
            '12. Изглажда релефа на кожата'
        ],
        faq: [
            'В кои случаи се препоръчва процедурата?',
            'Plasmatec се използва за премахване на разширени пори, белези от пъпки, акне, шарка, следоперативни белези, фини бръчки и стягане на отпусната кожа.',
            'Какви са предимствата на тази процедура?',
            'При  обработкаta се придизвиква ремоделиране на колагеновата структура и нейното обноваване по изключително прецизен начин. Плазмената и/или фракционна енергия стимулира образуването на здрав колаген и по този начин се подобрява релефа и структурата на белезите, намаляват бръчките и фините линии, подобрява се текстурата на кожата и се възстановява свежия й вид.',
            'Какво да очакваме по време на процедурата?',
            'Процедурата не е болезнена и не се налага използването на обезболяващи.',
            'Какво да очакваме след процедурата?',
            'Една седмица след процедурата , кожата се усеща по-суха и  по-груба . Има финно и почти незабележимо излющване.',
            'Какви грижи трябва да полагаме след такава процедура?',
            'Необходимо е да се използват хидратиращи и слънцезащитни кремове, които да овлажняват кожата допълнително. Противопоказно е използването на ексфолианти за период от около 4 седмици, както и излагане на слънце, силен вятър, плуване в солена или хлорирана вода. Забранено е саморъчното , принудително излющване на кожата.',
            'Необходим брой процедури?',
            'Всеки случай носи своите индивидуални характеристики, но са необходими не по-малко от 8 процедури.'
        ],
        prices: ['Цена за 1бр. процедура – 120 лв.',],
        promos: [`Цена за 1бр. процедура – От 120 лв. на ${adjustPrice(120, 25)} лв.`,],
    },
    {
        name: 'phenolov-piling',
        price: `${adjustPrice(250, 0)} лв.`,
        imageUrl: '/img/face-procedures/phenolov-1.jpg',
        shortDescription: 'Фенолов пилинг',
        descriptions: [
            'ФЕНОЛОВ ПИЛИНГ – ПЛАСТИЧНА ОПЕРАЦИЯ БЕЗ СКАЛПЕЛ',
            'КРАЛЯ НА ПОДМЛАДЯВАНЕТО И БОРБАТА С НЕСЪВЪРШЕНСТВА ПО КОЖАТА.',
            'Феноловият  пилинг е специална процедура не само сред пилингите, но и сред всички възможности за естетична корекция.',
            'И до днес  остава „златен стандарт“ сред пилингите в естетичната медицина. Това се дължи на неговата  ефективност и  на съвременните постижения в медицината, биохимията и фармакологията. В същото време еволюцията на фенолните пилинги върви към намаляване на токсичността, като същевременно поддържа отлични резултати.',
            'Фенолът предизвиква денатурация на протеините, осигурява ексфолиация, стимулира синтеза на протеини и гликозаминогликани, като по този начин повишава здравината, еластичността и стегнатостта на кожата.',
            'След фенолов пилинг кожата изглежда, като след пластична операция.',
            'Това е дълбок пилинг, който може да проникне до ретикуларния слой на дермата.',
            'При правилно провеждане на процедурата с подходяща козметична поддръжка по време на рехабилитационния период резултатът ще бъде впечатляващ, както по интензивност на подмладяващия ефект, така и по продължителност (8-10 години в зависимост от първоначалното състояние). кожа, възраст, начин на живот на пациента и накрая грижа за кожата.',
            'Задължително е обработената зона да не се излага на слънце до месец.',
        ],
        faq: [
            'Кои зони могът да се третират с фенолов пилинг?',
            'Лице, шия, деколте, гърба на дланите.',
            'Какъв е ефектът от процедурата?',
            'Видимо подмладява, уплътнява и стяга  зрялата кожа, чуствително и видимо подмладява кожата още след първата процедура и заглажда релефа.',
            'Имали възтановителен период?',
            'Да. Възтановителният период е около 5-6 дни. За повече информация относно възтановителния процес може да се свържете с нашите специалисти.',
            'За какво най-често се прилага фенолов пилинг?',
            'Феноловия пилинг третира успешно: фини линии и по-дълбоки бръчки, еластоза, доброкачествени образувания, увредена от слънцето кожа, белези от акне и шарка, розацея и купероза, пост-оперативни белези и др.',
            'В кои случаи НЕ е подходящя процедурата?',
            'Бременност и кърмене, остри кожни инфекции, при злокачествени образувания, активен херпес, хронични и соматични заболявания',
            'Има ли нужда от предварителна подготовка?',
            'Да, крем за профилактика за хиперпигментация два пъти дневно в продължение на поне 14 дни преди процедурата. Един месец преди процедурата, изключване на  всякакви травматични ефекти върху кожата (лазерен ресърфейсинг, солариум или слънчеви бани, почистване, кремове с киселини и ексфолианти).',
            'Колко процедури са необходими?',
            'Желаните резултати се постигат с 1 до 3 процедури, а ефектът се задържа повече от 2 години.'
        ],
        advantages: ['Стегната и еластична кожа', 'Подчертава овала на лицето',],
        prices: ['Цена за 1бр. процедура – 250 лв.',],
    },
    {
        name: 'karbonov-piling',
        price: `${adjustPrice(150, 0)} лв.`,
        imageUrl: '/img/face-procedures/carbon-1.jpg',
        shortDescription: 'Лазерен карбонов пилинг',
        descriptions: [
            'Лазерният карбонов пилинг е неинвазивна, безболезнена процедура, която комбинира силата на лазерната технология и активен карбон.',
            'За целта върху лицето се нанася слой течен карбон, който прониква дълбоко в порите и в последствие се разбива на фини частици с помощта на мощен лазерен лъч. В резултат кожата е почистена, премахват се замърсяванията и мъртвите клетки. Лазерната енергия активира клетките, които произвеждат колаген и еластин, което води до избистряне на тена и уплътняване на кожата.',
            'Подобрява текстурата на кожата.',
            'Карбоновият пилинг е особено подходящ за хора с акне и склонна към омазняване кожа, тъй като лазерният лъч има бактерициден ефект, унищожаващ патогенната микрофлора, което води до намаляване на възпалителните процеси.'
        ],
        advantages: ['Намалява акнето и белезите от него', 'Намалва фините линии и бръчки', 'Минимизира порите', 'Изсветлява кожата',],
        prices: ['Цена за 1бр. процедура – 150 лв.',],
    },
    {
        name: 'biorepeel',
        price: `${adjustPrice(150, 0)} лв.`,
        imageUrl: '/img/face-procedures/biorepeel.jpg',
        shortDescription: 'BioRePeel Cl3 биостимулиращ пилинг',
        descriptions: [
            'BioRePeel Cl3 е иновативна процедура, която използва патентована двуфазна технология. Предизвиква биостимулиращ, ревитализиращ и пилинг ефект.',
            'Богатсвото на съставките му различава BioRePeel Cl3 от конкурентите му. Продуктът е изключително подходящ в борбата срещу видимите признаци на стареенето, защото съдържа всички аминокиселини и витамини, от които кожата има нужда. Също така, BioRePeel Cl3 намалява вредите върху кожата от УВ лъчите, пигментациите и акнето. Освен нежния ексфолиращ ефект, процедурата стимулира биосинтезиращите процеси в кожата, които подобряват и възстановяват нейната структура. В допълнение, процедурата е подходяща и за по-млада кожа с активно акне, черни точки или скорошни белези от акне, както и за третиране на фини линии.',
            'Процедурата най-често се прилага на лице, шия и деколте. Може да бъде използвана и на различни зони по тялото – ръце, лакти, задни части, колене и ходила.',
            'Предимствата на двуфазната формула на BioRePeel Cl3:',
            'ЛИПОФИЛНА ФАЗА:',
            '1. Стабилизира и превантира',
            '2. Стабилизира трихлороцетната киселина в продукта, водейки до по-добра ефективност',
            '3. Запечатва и спомага проникването на активни вещества',
            '4. Намалява транс-епидермалната евапорация на вода и асистира проникването и транспорта на активни вещества от кожата',
            '5. Регенерира, хидратира, възстановява и поддържа кожната бариера',
            '6. Повишава нивата на хидратация чрез директни и индиректни методи',
            'ХИДРОФИЛНА ФАЗА:',
            '1. Биостимулация',
            '2. Стимулира фибробластите и активира кожните биосинтетични процеси',
            '3. Ревитализация',
            '4. Усилва клетъчния метаболизъм, синтеза на колаген, еластин и хиалуронова киселина',
            '5. Премахва повърхностните клетки на епидермиса, като по този начин улеснява проникването на активни вещества',
            '6. Антиоксидантен и анти-ейдиджинг ефект',
            '7. Намалява фините линии чрез неутрализиране на свободни радикали',
            '8. Помага срещу акнето, унищожава комедоните и действа върху разширените пори'
        ],
        advantages: ['Намалява акнето и белезите от него', 'Намалва фините линии и бръчки', 'Минимизира порите', 'Изсветлява кожата',],
        faq: [
            'Какви са ползите от BioRePeel Cl3?',
            'Не е необходима упойка, незабавни резултати, подходяща е за всяка възраст, няма възстановителен период и не причинява фоточувствителност',
            'Как протича  процедурата с BioRePeel Cl3?',
            'Почисиства се лицето и се нанася BioRePeel Cl3. Той стимулира естествни лечебни процеси в кожата. Усеща се леко парене и пощипване. Почиства се и се продължава протокола.',
            'Колко време е продължителността на процедурата?',
            'Процедурата продължава от 30 до 50 минути в зависимост от третирания проблем.',
            'Необоходима ли е домашна грижа?',
            'BioRePeel Cl3 е нежен и щадящ , няма възсъновителен период и се прилага и през лятото. Задължително е използването на правилна домашна грижа и слънцезащита.',
            'Колко процедури BioRePeel Cl3 са необходими?',
            '4-6 процедури на всеки 7-12 дни за лице, шия и деколте / 4-6 процедури на всеки 10-12 дни за тяло.'
        ],
        prices: ['Цена за 1бр. процедура – 150 лв.',],
        // promos: [`BioRePeel Cl3 процедура – От 150 лв. на ${adjustPrice(150, 30)} лв.`,],
        // packetPrices: [`Пакетна цена за 10 процедури – От 1200.00 лв. на ${adjustPrice(1200, 20)} лв.`],
    },
    {
        name: 'hydroclean',
        price: `${adjustPrice(80, 0)} лв.`,
        imageUrl: '/img/face-procedures/hydroclean.jpg',
        shortDescription: 'Водородно почистване и водороден пилинг',
        descriptions: [
            'Използването на технология за хидрогениране на водата предоставя възможности не само за пречистване, но и за забавяне на стареенето. ',
            'Допълнителна молекула водород улавя свободни кислородни молекули (свободни радикали) по повърхността на кожата и се комбинира във водна молекула, която е безвредна за кожата и тялото.',
            'Действието на  ултра-малките мехурчета  върху кожата, ускорява  ексфолирането, почиства  лицето дълбоко; премахва стареещите рогови клетки и себума и старателно премахва всички замърсявания, акари и мазнини, като в същото време доставя хранителни вещества на кожата, за да я направи хидратирана, деликатна и блестяща.',
            'Провеждането на това лечение в серия дава впечатляващи ефекти от подмладяване и изсветляване на кожата.',
            'Благодарение на специалните накрайници, почистващият ефект е много точен, а лечението се  провежда  дори върху деликатна и чувствителна кожа.',
            'Има много методи за почистване на лицето, които се използват у дома или в професионален салон. Благодарение на тях кожата е по-изчистена, гладка и по-блестяща и по-малко податлива на образуването на пъпки и черни точки.',
            'Въпреки това, има почистващо лечение, което може да предложи повече за кожата и това е ПОЧИСТВАНЕТО С ВОДОРОД !!!',
            'ТРЕТИРА УСПЕШНО:',
            '1. Почистването с водород  премахва нечистотиите и червените епидермални клетки от кожата',
            '2. Почиства и намалява  порите, черните точки, акарите, праха и мъртвата кожа',
            '3. Подобрява и регулира мастната секрецията  и коригира pH стойността на кожата',
            '4. Премахва акне, белези/белези от акне и разширени пори',
            '5. Избелва и хидратира  кожата, подобрява блясъка на кожата',
            '6. Подобрява еластичността, прави кожата компактна и устойчива  на стареене, намалява дълбоките и премахва  фините бръчки',
            '7. Устойчивост на свободния радикали , премахва  вредния реактивен кислород',
            '8. Стимулиране на кръвообращението, премахва черни кръгове и торбички  под очите',
            '9. Видимо избистря тена , освежава и регенерира, без да нарушава естествената мъ защитна бариера',
        ],
        faq: [
            'Какви са ползите от водородно почистване?',
            'Не е необходима упойка, незабавни резултати, подходяща е за всяка възраст, няма възстановителен период и не причинява фоточувствителност',
            'Колко процедури са необходими?',
            'По 1 процедура месечно през първите 5 месеца , а след това по 1 процедура през месец.',
            'Кога не се прилага процедурата?',
            'Няма противопоказния.'
        ],
        prices: ['Цена за 1бр. процедура – 80 лв.',],
        // promos: [`Цена за 1бр. процедура – От 80 лв. на ${adjustPrice(80, 30)} лв.`,],
    },
    {
        name: 'plasmatec',
        price: `${adjustPrice(120, 0)} лв.`,
        imageUrl: '/img/face-procedures/plasmatec-peel.jpg',
        shortDescription: 'Plasmatec апаратен пилинг',
        descriptions: [
            'Точно решение, когато пациентът търси високо ефективно, дълбоко обновяване и подмладяване на кожата.',
            'Процедурата сe провежда с класическа или фракционна честота , т.нар. фракционен  рисърфисинг.',
            'Plasmatec е най-висок клас от  апаратурата , създадена от Rene  – лидер в производството на медицински лазери от 1977 г. Технологията позволява  пълен контрол върху дълбочината на действие при извършване на терапията, за да бъде максимално прецизна.',
            'Апарата извършва  т.нар. класически лазерен пилинг или лазерен рисърфисинг. При тази процедура се премахва целият горен слой на кожата, за да се обнови повърхността на кожата, да се активира регенерацията на клетките и да се стимулира производството на нови и здрави колагенови и еластинови влакна. Всеки контакт на апарата с кожата предизвиква изпаряване на микрони от епидермиса и така напълно контролирано се достига до желаната дълбочина на действие, като се избягва риска от нежелани реакции.',
            'Лазерният ресърфисинг води до уплътняване и стягане на кожата. Процедурата изглажда повърхността на кожата, свива порите, заличава фини, по-дълбоки бръчки, белези от акне, както и белези от различно естество. Като краен резултат настъпва цялостно подобрение на релефа и текстурата на кожата.',
            'Апарата е най-висок клас и начина на въздействие и  резултатите се отличават от до сега съществуващите апарати.',
            'Процедурата е напълно безболезнена , без изгаряния , без агресивно въздействие върху кожата, без възстановителен период.',
            'Задължително е обработената зона да не се излага на слънце до месец.',
        ],
        faq: [
            'Кои зони могът да се третират с plasmatec пилинг?',
            'Лице, шия, деколте, гърба на дланите.',
            'Какъв е ефектът от процедурата?',
            'Видимо подмладява, уплътнява и стяга  зрялата кожа, чуствително и видимо подмладява кожата още след първата процедура и заглажда релефа.',
            'За какво най-често се прилага plasmatec пилинг?',
            'Plasmatec пилинг третира успешно: фини линии и по-дълбоки бръчки, еластоза, доброкачествени образувания, увредена от слънцето кожа, белези от акне и шарка, розацея и купероза, пост-оперативни белези и др.',
            'Колко процедури са необходими?',
            'Желаните резултати се постигат с 1 до 3 процедури, а ефектът се задържа повече от 2 години.',
            'Кога не се прилага процедурата?',
            'Бременност и кърмене, кожни инфекции и злокачествени образувания'
        ],
        prices: ['Цена за 1бр. процедура – 120 лв.',],
        // promos: [`Цена за 1бр. процедура – От 120 лв. на ${adjustPrice(120, 30)} лв.`,],
    },
    {
        name: 'plasmatec-papilomi',
        price: `${adjustPrice(50, 0)} лв.`,
        imageUrl: '/img/face-procedures/plasmatec-1.jpg',
        shortDescription: 'Plasmatec - премахване на папиломи...',
        descriptions: [
            'PLASMATEC  ПРЕМАХВАНЕ НА БЕНКИ, ПАПИЛОМИ, КОНДИЛОМИ, ХЕМАНГИОМИ, БРАДАВИЦИ, КОКОШИ ТРЪН, КСАНТЕЛАЗМИ И ДРУГИ ОБРАЗУВАНИЯ.',
            'Доброкачествените  новообразувания, като  бенки, папиломи, кондиломи, хемангиоми, брадавици, кокоши трън, ксантелазми и др. са често срещани по кожата на много хора.  Появяват в следствие на прекомерно излагане на слънце, хормонални нарушения, промени свързани с възрастта, наследствена предразположеност, нарушаване целостта на кожата, вирусни инфекции и др. Често  срещаните обикновени папиломи (прости,плоски или плантарни брадавици) се причиняват от т.нар. папилома вирус. Кожните образуванията могат да променят формата и цвета си, да увеличават размера си, да причинят дискомфорт (сърбеж, парене) и да кървят. Ето защо те често представляват и козметичен дефект, влияещ пряко върху външността и самочувствието на човека. Някой от тях изискват особено внимание и диагностика.',
            'PLASMATEC премахване на тези образувания е най-успешния и деликатен метод за кожата.',
            'Отстраняването е базирано на принципа на аблация (изпаряване) на клетките. Кожното образувание се третира с високоинтензивен фракционен плазмен лъч , което води до унищожаване на патологично променените тъкани. Няколко дни след процедурата на мястото на дефекта се появява коричка. За да избегне инфекция и поява на белези , коричката трябва сама да падне. Кожата под нея остава чиста и гладка.',
            'Методът се характеризира с малка травматичност, липса на кръвотечение по време на процедурата и липса на възпалителна реакция след процедурата (поради липса на дермакационна линия). Това позволява след извършването на  интервенцията оздравителният процес протича в много бързо.',
            'ПРЕДИМСТВА:',
            '1. Липса на кървене по време на лазерната манипулация.',
            '2. Пълна стерилност на процедурата (с кожата контактува единствено лазерния лъч).',
            '3. Липса на възпалителни процеси – поради високата температура на лазерния лъч след приключване на процедурата, в раната има пълна липса на микроорганизми.',
            '4. Лесното достигане до нужните в дълбочина засегнати участъци на кожата.',
            '5. Бързо възстановяване – 1 до 3 седмици в зависимост от вида и размера на отстраненото образувание (лазерът стимулира регенерацията на клетките на кожата).',
            '6. Безболезненост – премахването се извършва с локална анастезия, но дори и без нея, повечето пациенти изпитват само лек дискомфорт.',
            '7. Висока естетичност – след процедура липсват белези, изгаряния и пигментации.',
            '8. Бърза рехабилитация – възможността с прецизна точност да се контролира дълбочината на проникване и отстраняване води до по малка травма и по-бързо възстановяване.',
            '9. Кратко времетраене на манипулацията –  премахването  на образувания е бърза процедура (отнема само няколко минути), което позволява в едно посещение да бъдат премахнати множество образувания.',
            '10. Прецизност на метода – заради ултратънкия лъч  се премахва  само засегнатата тъкан, без да се засяга здравата кожа.',
        ],
        faq: [
            'Какво да очакваме по време на процедурата?',
            'Върху образуванието се поставя локална анестезия и се отстранява.',
            'Какво да очакваме след процедурата?',
            'След процедурата по кожата се появяват корички, които не се отстраняват принудително.',
            'Какви грижи трябва да полагаме след такава процедура?',
            'Необходимо е използването на правилна домашна грижа. Ние ще ви посъветваме в съответсвие на вашия случай.',
            'Необходим брой процедури ?',
            'Само една процедура е достатъчна.',
            'Има ли противопоказания?',
            'Да! Не се работи при онкологични или автоимунни заболявания, захарен диабет тип I, епилепсия, обострена херпесна инфекция, бременност и при общо неразположение – настинка.'
        ],
        prices: ['Цена за 1бр. образование – 50 лв.', 'Цена за 5 до 10 образования – 30 лв. на брой.',],
        // promos: [`Цена за 1бр. процедура – От 50 лв. на ${adjustPrice(50, 30)} лв.`,],
        packetPrices: ['В случай на повече образования ще ви изготвим пакетна цена след безплатна консултация с наш специалист.'],
    },
    {
        name: 'pochistvane-ultrazvuk',
        price: `${adjustPrice(80, 0)} лв.`,
        imageUrl: '/img/ultrazvuk.jpg',
        shortDescription: 'Почистване на лице с ултразвукова шпатула',
        advantages: ['Без зачервявания и раздразнения', 'Обновява и тонизира кожата', 'Минимизира разширените пори', 'За всеки тип кожа'],
        descriptions: [
            'ПОЧИСТВАНЕ НА ЛИЦЕ С УЛТРАЗВУКОВА ШПАТУЛА',
            'Почистването с ултразвукови вълни  премахва мъртвите рогови клетки от най-горния слой на епидермиса, прониква дълбоко под повърхността на кожата, насърчавайки клетъчното обновяване и възстановяване, тонизиране на мускулите, увеличава кръвообращението, насърчава лимфния дренаж и подобрява проникването на продуктите за грижа за кожата.',
            'Процедурата е подходяща за всеки тип кожа, почиства и минимизира разширените пори, обновява и тонизира кожата, стимулира производството на колаген и еластин и има деликатно действие без зачервявания и раздразнения.',
            'Ултразвуковите шпатули за почистване на кожата действат по два начина:',
            '1. Използвайки ултразвукови вибрации за ексфолиране на кожата и извличане на излишния себум и примеси.',
            '2. Плъзгайки се по кожата с обратната страна, за да се вкарат в дълбочина полезни съставки.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Лице',
            '2. Шия',
            '3. Деколте',
            '4. Гръб',
            'ТРЕТИРА УСПЕШНО:',
            '1. Мазна и акнеична кожа',
            '2. Премахва замърсяванията',
            '3. Видимо подмладява.',
            '4. Уплътнява и стяга.',
            '5. Изглажда релефа на кожата'
        ],
        faq: [
            'Каква е препоръчителната честота на процедурата?',
            '1 – 2 пъти седмично в зависимост от състоянието и поведението на кожата, като продължителността на процедурата е около 20-30 минути.',
            'Какви са предимствата на почистването на лицето с ултразвук?',
            'Процедурата е нежна и неинвазивна – за разлика от мануалното почистване. Изключително подходяща при мазна, смесена и акнеична кожа. Ефекта се вижда веднага  след първата процедура – кожата е тонзиирана и освежена. Тази поцедура стимулира производството на колаген и действа антибактериално. Подходяща е и за дами в напреднала възраст, ултразвукът стимулира кръвообръщението и производството на колаген. Процесът е високоефективен, без дискомфорт, зачервяване или болка.',
            'Кога не се прилага процедурата?',
            'При кожни заболявания по лицето, след химичен пилинг, при остри инфекциозни заболявания, бременост, остри инфекциозни заболявания, отоци, зони след лъче и радиотерапия, до 3 месеца след спиране на системно лечение с ретиноиди, сърдечно съдови заболявания и епилепсия.'
        ],
        prices: ['Цена за 1бр. процедура – 80 лв.',],
        // promos: [`Цена за 1бр. процедура – От 80 лв. на ${adjustPrice(80, 30)} лв.`,],
    },
    {
        name: 'microneedling',
        price: `${adjustPrice(100, 20)} лв.`,
        imageUrl: '/img/face-procedures/microneedling.jpg',
        shortDescription: 'Микронидлинг',
        descriptions: [
            'Процедурата представлява дозирано „травмиране” на епидермиса и дермата, което отключва естествен процес на регенерация. Засилва се потока от растежни фактори, цитокини и други активни молекули. Синтезират се нови, здрави колагенови и еластинови влакна',
            'Процедурата микронидлинг е насочена към подмладяване на кожата на лицето и тялото, лечение на акне и пост-акне, включително белези, както и коригиране на различни кожни промени (стрии, пигменти, целулит). По време на процедурата на микронидлинг се случва следващото:',
            'Първо, възпалението се отстранява и еластичността и твърдостта се подобряват поради инжекции на антиоксидантни или колаген-стимулиращи лекарства в повърхностните слоеве на кожата.',
            'Второ, клетъчната регенерация и метаболитните процеси в клетките се стимулират поради микроповреди на кожата със сноп тънки игли. Повърхностните слоеве на кожата се обновяват, елиминират се белези, пигментация, стрии. В същото време тонът на кожата се изравнява и се появява естествен блясък.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Лице',
            '2. Шия',
            '3. Деколте',
            '4. Гръб',
            'ТРЕТИРА УСПЕШНО:',
            '1. Отпуснатост на кожата',
            '2. Дълбоки и повърхностни бръчки',
            '3. Пигментация',
            '4. Акне',
            '5. Наличието на пост-акне',
            '6. Разширени пори',
            '7. Стрии по кожата',
            '8. Белези по лицето',
            '9. Целулит',
            '10. Косопад'
        ],
        faq: [
            'Колко процедури са необходими?',
            'Препоръчителен курс от 3-4 процедури',
            'Кога не се прилага процедурата?',
            'Остри инфекции, кръвни заболявания, изгарания, увреждане на кожата, купероза, инкоагулация на кръвта, бременност и кърмене, колоидни белези, прием на хормони (кортикостероиди) и стимуланти на кръвосъсирването и онкологични заболявания.'
        ],
        prices: ['Цена за 1бр. процедура – 100 лв.',],
        promos: [`Цена за 1бр. процедура – От 100 лв. на ${adjustPrice(100, 20)} лв.`,],
    },
];


export { faceProceduresData };