const therapiesData = [
    {
        name: 'vertebra',
        price: '50 лв.',
        imageUrl: '/img/theraphy/vertebra-1.jpg',
        shortDescription: 'Динамична електро невро стимулация - Vertebra',
        descriptions: [
            'Има хора, които знаят как да се направи добра медицинска апаратура, има лечения, които не могат да оставят никого безразличен, има части на тялото, които са най-нуждаещи се от лечение - има опашка от тези, които желаят да вземат превантивни мерки или да се лекуват с апарата  Vertebra!',
            'Как работи апарата?',
            'Необходимостта от въздействие  на различни части на гърба не възниква само когато болката е в гърба на пациента. По гръбначния мозък и нервите на гръбначния стълб , се простират БАТ, за контрол на функцията на вътрешните органи и части на тялото. Оказва се, че при лечението на много (ако не и повечето) от хроничните болести  и заболявания е правилно  да се  работи по  конкретен участък на гръбначния стълб и паравертебралните области. И за да се получи общорегулярен ефект се препоръчва да се извършва въздействие по  дължината на гръбнака - от втория шиен прешлен до опашната кост. Този начин е потвърден с  клинични проучвания за ефикасността на невростимулациите и потвърждава не само анестетичен ефект в патологията на гръбначния стълб, но също така и подобряване на кръвоносната система, лечение и нормализиране на функционирането на органите, при  редица тежки заболявания на вътрешните органи.',
            'Уникална, индивидуална биоуправляема терапия за лечение на тежки, остри, хронични заболявания и функционални нарушения на вътрешни органи. Курсово лечение, профилактика и рехабилитация на заболявания на вътрешни органи и опорно-двигателен апарат.',
            'Vertebra - терапията е универсална рецепта за процедури.',
            '1. Предварително установени проекции на  зоните болния орган или част от тялото (т. нар. пряка проекция на оплакването).',
            '2. Предварително установенa зона - сегмент - определена област на гърба, разположен на нивото на оплакването.',
            'При прилагането на универсални портативни устройства Vertebra-терапията трябва да бъде избрана  зоната, режима и честотата на експозицията. При прилагането на Вертебра, тя автоматично настройва параметрите на стимулация.',
            'Зона на маточната шийка: органите на главата и шията (съдове, нерви, горни дихателни пътища, зъбите, щитовидната жлеза, мозък и други), горни крайници (кръвоносни съдове, стави, нервна система).',
            'Зона гърди: гръдни органи (сърце и кръвоносни съдове, лимфни възли, бронхопулмонална система, стомах, черен  дроб, панкреас), горните крайници (кръвоносни съдове, стави, нерви).',
            'Сакрална – Лумбална област: долни крайници (кръвоносни съдове, стави, нервна система), черва, мъжки и женски полови органи, отделителна система. С избора на врата и гърба, като определени органи за Vertebra-терапия са идентифицирали няколко области на приложение.',
            'Четиридесет и осем електроди на устройството работят  в определен ред, в определен размер и честотата на стимулация. Субективно по време на процедурата , човек изпитва чувство на комфорт и това се дължи на ефекта на "бягащата  вълна". Тази "вълна" е моделирана по лабилен начин да се движи и превежда сигналите и същевременно да прави масаж. Но в този случай става дума за масаж с електрически импулс.',
            'МОЛЯ ДА НЕ СЕ БЪРКА С ЕЛЕКТРОПУЛСОВО ЛЕЧЕНИЕ С ВИБРАЦИИ !!!',
            'Липсата на вибрации  в експозицията на  апарата  ДЭНАС-ВЕРТЕБРА прави възможно и единствено правилният начин за използването му при остра болка и травма, остеопороза и херния на междупрешленните дискове. Създаване на комплекс от модулации с импулсен ток позволява процедурата да се провежда с различни ефекти от действие: погалване, месене и почукване и т.н.',
            'По време на процедурата, цялата експозиция се извършва на гърба по протежение на гръбначния стълб. Съсредоточава се върху всеки прешлен. Активната  смяна на електроди първо на гърба, по  средната линия на тялото, следвани от паравертебалните зони на шийните, торакалните и лумбалните сегменти дава краен и ясно изразен ефект като цяло.',
            'За разлика от другите програмати, при тази  се прилага високо стимулиране , което осигурява подобряване на  метаболитните и трофични процеси във всички тъкани и органи. Програмата се прилага и  за лечение на функционални нарушения на опорно-двигателния апарат и регулирането на функциите на вътрешните органи (мускулно пренапрежение, дискинезия на храносмилателната система, бронхопулмонални заболявания, ендокринни  и т.н. ). Ако първите получени регулаторни ефекти, започват с нормализирането  на мускулния тонус и завършват във функциите  на взетия орган, то пулта на управление на апарата показва  програма С.',
            'Когато е необходимо да се "събира" тялото в едно устройство, за да се възстанови бързо от заболявания и да  подобри  на ефективността, ще са необходими от 1-30 процедури на програма А. / Назначават се от специалст, съобразно тежестта на заболяването.',
            'ДОКАЗАТЕЛСТВА:',
            'Всяка машина, произведена от корпорацията, преминава  някoлко  етапа, свързани с  одобрение, изпитване и изследване. Едно от тези изследвания  е проведено във водеща клиника.',
            'В процеса на валидация е проведен лечбно – профилактичен курс с ДЕНАС. Проведени са над  350 процедури (всеки участник в изследването е провел курс на лечениие  (7-30 процедури). Случай на отказ от терапията не е докладван.',
            'Отчетени резултати:',
            '- Възстановяване  на гърба – 72%',
            '- Нормализиране на артериалното налягане от сенса  - 36%',
            '- Намаляване или облекчаване на болки в гърба - 98%',
            '- Намаляване или облекчаване на болки в областта на шията – при всички, който са съобщили оплаквания.',
            '- Намаляване или освобождаване от главоболие - 42%',
            '- Увеличаване на трудосподобността – няма данни',
            '- Подобряване на общото здравословно състояние, настроение и работоспособност - 100%',],
        faq: [
            'За какво се прилага Vertebra?',
            'За повишаване на общите адаптационни възможности на организма, силна болка в гърба, свързани със заболявания или увреждания на гръбначния стълб и мускулите на гърба. Спастична болка при заболявания на вътрешния органи. Рехабилитация при постоперативни състояния. При пренатоварване на мускулите на гърба (умора, "подтиснати" мускули и т.н.), в следствие на  непредвидими обстоятелства. Лечение на праговите зони.',
            'Кога НЕ се прилага Vertebra?',
            'Индивидуална непоносимост към електрически ток, наличието на имплантиран пейсмейкър /кардиостимулатор/, епилептичен статус, новообразувания - не се работи пряко върху тях, остра треска с неизвестен произход, венозна тромбоза, състояние на остри психически, алкохолни или наркотични въздействия.',
            'Необходим брой процедури?',
            'Въпреки, че процедурите могът да бъдат правени поединично, НАЙ-ВИСОКО ИЗРАЗЕН ЛЕЧЕБЕН ЕФЕКТ НАСТЪПВА САМО И ЕДИНСТВЕНО ПРИ КУРСОВО ЛЕЧЕНИЕ! ПРОДЪЛЖИТЕЛНОСТТА МОЖЕ ДА БЪДЕ ОТ 14 ДО 30 ПРОЦЕДУРИ. ПЕРИОДИЧНОСТТА НА ЛЕЧЕНИЕ СЕ ОПРЕДЕЛЯ ОТ СЪСТОЯНИЕТО НА ПАЦИЕНТА И ДРУГИ ФАКТОРИ, КОИТО СЕ НАЗНАЧАВАТ ОТ СПЕЦИАЛИСТ.',
            'Какво да очакваме по време на процедурата?',
            'Процедурата не е болезнена, а някои дори я намират за приятна и релаксираща.',
            'Има ли противопоказания?',
            'За подходящи процедури с Vertebra, както и за да се определи оптималната комбинация с други лечения се изисква консултация с наш специалист.'],
        prices: [
            ' От 10 мин. до 40 мин. спрямо лечението – 50 лева на процедура.'
        ],

    },
    {
        name: 'nls',
        price: '200 лв.',
        imageUrl: '/img/theraphy/nls.jpg',
        shortDescription: 'Нелинейна компютърна диагностика - NLS',
        descriptions: [
            'ДИАГНОСТИКА НА ЦЕЛИЯ ОРГАНИЗЪМ ЧРЕЗ НЕЛИНЕЕН СИСТЕМЕН АНАЛИЗ (NLS – ДИАГНОСТИКА)',
            'ЕКСПРЕСНА КОМПЮТЪРНА ОЦЕНКА НА СЪСТОЯНИЕТО НА ОРГАНИЗМА',
            'БЕЗВРЕДНО И БЕЗБОЛЕЗНЕНО ИЗСЛЕДВАНЕ НА ВСИЧКИ ОРГАНИ И СИСТЕМИ',
            'ОТКРИВАНЕ НА ИНФЕКЦИОЗНИ ОГНИЩА',
            'ОТКРИВАНЕ НА БОЛЕСТНИ СЪСТОЯНИЯ И ПЪТИЩА ЗА ТЯХНОТО ОТСРАНЯВАНЕ',
            'ОЦЕНКА НА ИМУНИТЕТА', 'ЦВЕТНО ГРАФИЧНО ИЗОБРАЖЕНИЕ НА ОРГАНИТЕ И ТЕХНИТЕ ИЗМЕНЕНИЯ',
            'Тази диагностика позволява измерването на изменението на вълновата характеристика на магнитните полета на живите организми (известни като биовълни), на техните тъкани, отделните клетки, ензими и хормони. В рамките на един час абсолютно безвредно и безболезнено, с помощта на компютър се изследват всички системи в човешкия организъм на органно, тъканно и клетъчно ниво.',
            'Вие получавате ИНФОРМАЦИЯ за:',
            '1. Състоянието на сърце и бели дробове, щитовидна, задстомашна и хипофизна жлеза, хипоталамус, черен дроб и жлъчен мехур, бъбреци, полови органи, млечни жлези и простата.',
            '2. Ранна диагностика на заболявания като: захарен диабет, артериална хипертония, инфаркти, инсулти, аденоми, злокачествени новообразувания и др.',
            '3. Хронични заболявания и посттравматични състояния.',
            '4. Състоянието на имунната система.',
            '5. Предразположеност към алергии и техните причинители (алергени), токсичност',
            '6. Наличие на вируси, паразити и бактерии.',
            '7. Изследването се документира чрез комплект от цветни разпечатки на проблемните органи и епикриза.',
            'Лечение:',
            '1. Комплексни профилакични и лечебни програми в зависимост от стадия на заболяването.',
            '2. Терапия "Метатрон" за възстановяване на естествения акционен потенциал на клетъчната мембрана, водещ до нормалния и биохимизъм.',
            'ОПИСАНИЕ НА ИЗСЛЕДВАНЕТО НАКРАТКО:',
            'Експресен, безболезнен и неинвазивен преглед на целия организъм. Само за 1 час получавате информация за състоянието в момента на всички ваши органии системи. Възможност за това дава диагностиката с апарата "Метатрон" - руска (до преди няколко години засекретена) апаратура, използувана за космическа медицина. По настоящем се използва в NASA (САЩ), а в последните години и масово сред цивилното население в Русия, Украйна, Полша, както и почти всички държави от западна Европа. Официално апаратурата е призната за медицинска в Русия, Украйна, Германия, Чехия и Англия. В останалите държави все още се смята като алтернативен медицински метод.',
            'Сега всеки може да се възползува от нея и да надникне в своя организъм по различен от досега познатите ни методи на изследване. Дори може да провери до колко дадено лекарство е ефективно при неговото заболяване.',
            'Чрез апарата "МЕТАТРОН" се прави запис на електромагнитното излъчване на мозъка, където е проектирано състоянието на всяка клетка от органите и се изобразява на екрана като картина и графика, която се сравнява с графиката на нормалното функция според пола и възрастта, заложена в апарата.',
            'Така се установява има ли отклонение.',
            'Нелинейната диагностика представлява запис на електромагнитното поле на мозъка. Всеки орган има определена електромагнитна честота и по нея се определя промяната на функцията му.', 'Апаратът дава възможност да се съкрати времето за функционална оценка на организма.',
            'Регистрира измененията в органите и позволява:',
            '1. Да се получи качествена оценка на функционалното състояние на ортанизма.',
            '2.  Да се проконтролира адаптивната способност на организма.',
            '3. Да се проведе анализ на динамиката на функционалното състояние на организма по време на лечение.',
            '4. Да установи първичното място на функционалното нарушение.',
            '5. Да се оцени характера на патологията, използувайки експертни системи.',
            '6. Да се оценят основните параметри на хомеостазата.',
            'ДЕНАС ЦЕНТЪР БГ РАБОТИ С „METATRON“. АПАРАТА Е РАЗРАБОТЕН И ПРОИЗВЕДЕН ОТ ИНСТИТУТА ПО ПРИКЛАДНА ПСИХОФИЗИКА, КОИТО Е СЪЗДАТЕЛ И ЛИДЕР В ОБЛАСТТА НА РАЗРАБОТКА И ПРОИЗВОДСТВО НА НАЙ-ВИСОК КЛАС АПАРАТИ ЗА НЕЛИНЕЙНА ДИАГНОСТИКА !!!'
        ],
        faq: [
            'Как протича диагностиката?',
            'Диагностиката е напълно безвредна и безболезнена.  В комфортна среда се отпускате , поставят се два електрода и през цялото време имате възможност да комуникирате с оператора.',
            'Кога ще получа резултата?',
            'Резултата се получава до 7 дни. След приключване на процедурата оператора оформя резултатите и се изпращат за консултация.'
        ],
        prices: [
            'Цена - 200 лева'
        ],
    },
    {
        name: 'fx-405',
        price: '150 лв.',
        imageUrl: '/img/theraphy/fx-405.jpg',
        shortDescription: 'Лазер за лечение и лечени на болка - FX-405',
        descriptions: [
            'ПЪРВИЯТ И ЕДИНСТВЕН В БЪЛГАРИЯ НИСКОЧЕСТОТЕН ЛАЗЕР ЗА УПРАВЛЕНИЕ НА БОЛКАТА И ЛЕЧЕНИЕ ЗА ПАЦИЕНТИ И ЛЕКАРИ, КОИТО ТЪРСЯТ НАЙ - ЕФЕКТИВНОТО И ПРАВИЛНО ЛЕЧЕНИЕ, КАКТО И АЛТЕРНАТИВА НА ОПИОИДИ ЗА УПРАВЛЕНИЕ НА БОЛКАТА! ТЕХНОЛОГИЯТА ПОЗВОЛЯВА ПО - БЪРЗО ТРЕТИРАНЕ И ПО - ГЪВКАВА ТЕРАПЕВТИЧНА СХЕМА',
            'Нискочестотния лазер FX-405 , използващ електромагнитен , енергиен  трансфер е доказан МЕДИЦИНСКИ ПРОБИВ, който е единствен в света. За тази технология не са докладвани вредни странични ефекти или нежелани реакции, както и възможност за пристрастяване.',
            'Всички лазери  на Американската компания ERCHONIA са одобрени и сертифицирани за ускорено лечение и управление на болката и имат разрешения за многократна употреба без използване на инвазивни операции  от Американската Агенция за контрол на храните и лекарствата.',
            'С подкрепа на първо ниво, плацебо контролирани, двойно - слепи, рандомизирани, многоцентрови клинични проучвания, които включват FX-405. Посочените лазери са единствените в света, които са одобрени и сертифицирани за лечение на нелечими болки.',
            'FX-405 има два лазерни диода с монохромна кохерентна светлина с дължина на вълната  635 nm 7.5mW.',
            'ВИОЛЕТОВА СВЕТЛИНА:',
            '- Подобрява клетъчното дишане',
            '- Силно изразен противовъзпалителен ефект',
            '- Задейства производството на азотен оксид',
            '- Активира теломеразата',
            '- Оптимизира заздравяването на рани',
            '- Подобрява кислорода използван в тъканите',
            'ЗЕЛЕНА СВЕТЛИНА:',
            '- Подобрява афинитета към кислорода от органи и системи , което е важна част от лечението на всички заболявания',
            '- Подобрява митохондриалният енергиен метаболизъм и клетъчна детоксикация',
            '- Свързва се с хемоглобина и подобрява еластичността на клетките',
            '- Намалява млечната киселина', '- Намалява вискозитета на кръвта',
            '- Регенерира  и стабилизира  тъканите', '- Насърчава серотонин метаболизма',
            '- Подобрява настроението и жизнеността. Лекува депресии и други подобни състояния',
            '- Възстановява  метаболизма на вит.D ,  което е от съществено значение за възстановяването на костите,съединителната тъкан и имунна система', '- Предпазва от инфекции и регулира клетъчния и ДНК  метаболизъм', 'ЧЕРВЕНА СВЕТЛИНА:',
            '- Енергизира дейността на клетките и засилва клетъчната активност',
            '- Регулира притока на кръв и кислород',
            '- Активира клетъчните процеси на регенерация, контролирани от митохондриите',
            '- Повишава липидите, пероксидацията  и намалява холестерол в съдовете.',
            'FX -405:',
            '- Балансира парасимпатиковата нервна система и подобрява микроциркулацията на кръвта и лимфата',
            '- Повишава нивата на енергия чрез стимулиране на митохондриите',
            '- Увеличавайки производството на АТФ и протеиновия синтез, намалява болката',
            '- Ясно изразен противовъзпалителен ефект и лечебен ефект',
            '- Модулатор – увеличава енергийния поток и разсейва енергията, ако е твърде висока',
            '- Стимулира възстановяването на мозъчните клетки и подобрява мозъчната производителност (сътресения, невродегенеративни състояния, като Алцхаймер, деменция , Паркинсон , Аутизъм и др.',
            '- Подобрява заздравяването на неврологични патоизменения',
            '- Модулира имунната активност. Особено важно за мозъчни травми , тъй като микроглиалните клетки могат да започнат да атакуват здрава тъкан, ако не са модулирани',
            '- Подобрява баланса и координацията',
            '- Предпоставка за повишаване нивата на колаген при заздравяването на рани',
            '- Редуцира болката и мускулния спазъм като едновременно увеличава силата',
            '- Подобрява комуникацията между клетките',
            '- FX 405 предлага най-търсените процедури, като позволява провеждането на специализирана терапия при някои заболявания и състояния, без използване на лекарства и хирургични средства. Лазерно лечение се назначава за лечение и профилактика на многобройни и разностранни по етиология болести, като намира и особено широко приложение в хирургията, като позволява прецизна дейност и постигане на оптимални резултати на фона на минимален риск',
            'ПРИЛОЖЕНИЕ:',
            '- Мускулно-скелетни заболявания - Артрит и болки в ставите, Синдром на карпалния тунел,Тендинит,Бурсит и други.',
            '- Заболявания на нервна система, неврологични болки - Мигрена и Главоболие, Плантарен фасциит и други',
            '- Имунология / Урология / Стрес – инконтиненция / Проблеми на тазово дъно / Кардиология / Ендокринология / Белодробни болести / Вътрешни болести / Гинекология / Хирургически белези / Акне / Екзема / Херпес зостер/ Вътрешни болести / УНГ / Физиотерапия от висок клас и много други',
            '- Ускорява оздравяването',
            '- Със сериозни доказателства при лечнието на Аутизъм и Синдром на Даун',
            '- Синергично действие на червен и виолетов лъч, които го правят изключително мощен и ефективен',
            '- Изследванията на Erchonia установиха, че 635nm червените лазери са най-силното терапевтично средство за лечение на болка и възпаление. Червените лазери се използват за лечение на всяка клетка в тялото', '- Виолетовите лазери са 405nm лазери. Установено е, че тези специфични виолетови лазери инактивират микроорганизмите. Виолетовият лазер на Erchonia е доказано ефективно антивирусно, антибактериално и противогъбично лечение',
            '- За състояния като акне, херпес, стрептокок в гърлото, инфекции на синусите, гъбички, ухапвания от насекоми и животни, засегнати от Лайм, стави, екзема, херпес зостер, белези и много други е единственто успешно лечение', '- Комбинацията от червения и виолетовия лазер включва лечение на болка и възпаление с инактивиране на микроорганизми',
            '- Терапиите с FX – 405 са с широко приложение, добър профил на безопасност и висока ефективност, които при правилен подбор и изпълнение водят до постигане на желаните резултати при различните заболявания на фона на минимален риск от усложнения'
        ],
        faq: [
            'Кой може да се лекува с лазера?',
            'Клинично  доказано e, че няма възрастови ограничения за лечение с лазера. Проучванията показват, че LLLT е безопасно и ефективно лечение и при деца. Всъщност много педиатри и педиатрични физиотерапевти препоръчват FX – 405 - LLLT за деца с различни състояния. Например, FX-405 може да се използва за лечение на ушни инфекции и други инфекции, спортни травми и мускулно-скелетни състояния като ювенилен идиопатичен артрит и много други.',
            'Има ли болка при процедурата?',
            'Не. Процедурата е напълно безболезнена. Напротив обезболява и управлява силни и нелечими болки.',
            'Има ли странични ефекти?',
            'Едно от основните предимства на FX-405 е, че няма известни странични ефекти. За разлика от лекарствата или хирургията, FX-405 не включва използването на химикали или инвазивни процедури, които могат да навредят на тялото. Вместо това FX-405 действа, като стимулира естествените лечебни процеси на тялото, което го прави безопасен и нежен вариант за лечение и на деца. Друго предимство на FX-405е, че е неинвазивен, което означава, че не изисква никакви разрези или игли. Това може да бъде особено важно за деца, които може да се страхуват от медицински процедури или им е трудно да седят неподвижни за дълги периоди от време. Процедурите с FX-405 са бързи и безболезнени, които  се провеждат за 5 - 20 минути.'
        ],
        prices: [
            'Цена за 1 процедура - 150 лева'
        ],
        packetPrices: [
            'При закупуване на 15 процедури получавате - 100 лева на процедура'
        ],
    },
    {
        name: 'btl-shockwave',
        price: '25 лв.',
        imageUrl: '/img/theraphy/shock-1.jpg',
        shortDescription: 'Ударно-вълнова терапия - BTL Shockwave',
        descriptions: [
            'ВИСОКО-ЕФЕКТИВНО ЛЕЧЕНИЕ С ФОКУСИРАНА УДАРНО-ВЪЛНОВА ТЕРАПИЯ',
            'Ударно - вълновата терапия е въведена през 90-те години на миналия век, като изначално е разработена за бързо възстановяване на професионални спортисти, танцьори, циркови дейци – дейности, съпроводени от нарушения в работата на опорно-двигателния апарат и хронична болка. Болезнените състояния възникват не само от травми, а и от повтарящи се натоварвания в определена зона на тялото. Хроничната болка е ежедневие както при спортисти (много разпространени са т. нар. тенис-лакът, голф-лакът, коляно на скачач), така и сред офис-служителите с целодневно използване на компютърна мишка и клавиатура.',
            'Класическият подход в лечението включва физиотерапия, противовъзпалителни препарати, масаж. Схемата отнема 2 до 6 месеца многократни посещения без устойчив ефект, а медикаментите често са с противопоказания и странични ефекти.',
            'Днес ударно - вълновата терапия е достъпна за масово приложение. Без странични ефекти и без използване на обезболяващи. Тя работи със звукови ударни вълни, които постигат премахване на болката ВЕДНАГА - още след първата процедура. А за траен ефект са необходими от 5 – 10 процедури.',
            'Как реагира организмът при поява на болка? Прави всичко възможно да я намали като ограничава подвижността в ставата. Резултатът най-често е затрудненост при повдигане на ръката или свиване на крака дори при наглед елементарни движения като сресване, хващане за лоста в автобуса, слизане по стълби. В областта на засегнатата става възникват огнища на хронична болка и се забавя храненето й. Това рефлектира върху мускулите – съединителната им тъкан се уплътнява, получават се спазми и се затруднява разтягането им. В сухожилията и свързващите елементи намалява еластичността, а в мястото на прикрепянето им към костите започва отделяне на калциий с образуване на болезнени израстъци. Кръгът се затваря, когато спазмите в мускула провокират преразтягане на сухожилието, а преразтягането – към травма около ставата, съчетана с още по-силен спазъм в мускула. Веригата най-общо е: нарушаване на храненето в ставата – болка – затруднено движение.',
            'Ударно-вълновата терапия трайно освобождава движението. Бързо се разсейват уплътненията в мускулите и схващанията, подобрява се храненето на тъканите, увеличава се обемът на движенията, повишава се издържливостта на натоварвания. Процедурите се извършват с най-високия клас апаратура в областта на шоковите вълни, притежаваща изцяло нова формула фокусирана ударно – вълнова  енергия, която позволява да се третират и повърхностни, и дълбочинни зони, генериращи болка',
            'Честотата на ударната вълна е персонализирана спрямо индивидуалното състояние така, че едновременно с премахването на болката, да се стимулират и естествените оздравителни процеси на организма.',
            'Единствената технология, предлагаща най-дълъг живот с постоянна интензивност на ударите',
            'Перфектният баланс между висока енергийна плътност на потока и оптимален размер на фокалното зона',
            'Най-високия честотен диапазон на пазара за широк спектър от индикации',
            'BTL Shockwave ударно-вълновата терапия е неинвазивно решение за мускулно-скелетната болка. Екстракорпоралната ударно-вълнова терапия е най-често използвана във физиотерапията, ортопедията и спортната медицина. Приложенията ѝ  най-вече са свързани с лечение на хронични заболявания на мускулите и сухожилията.'],
        faq: [
            'Какво е времето за лечение?',
            'Вашият терапевт ще изготви план за лечение за вас. Повечето пациенти преминават от 8 до 10 сесии в продължение на няколко седмици.',
            'Какво е усещането при процедурата?',
            'Процедурата се основава на излъчването на целеви вибрации в третираната тъкан. По време на процедурата ще изпитате интензивна механична стимулация',
            'Какъв е възстановителния период?',
            'Лесно можете да провеждате лечението дори по време на обедната си почивка и веднага да се върнете към ежедневните си дейности.', 'Кога мога да видя ефекта от процедурата?', 'Това много зависи от третираната зона, нейното състояние и други фактори. Видими подобрения се виждат още след първите процедури.',
            'Какви са възможните странични ефекти?',
            'При някои пациенти може да се наблюдава зачервяване, оток,  хематоми, петехии, временна загуба на чувствителност или поява на сърбеж в обработената зона.',
            'Може ли да се правят комбинации с други апарати и процедури?',
            'Процедурите с апарата X-Wave могат да се извършват самостоятелно или като част от пакетни процедури, включващи радиочестота (Exilis, Vela Shape III), криолиполиза (CoolTech), мезотерапия и други.'
        ],
        prices: [
            'За възрастни – 50 лева на процедура', 'За деца – 25 лева на процедура'
        ],
        packetPrices: [
            'При закупуване на 6 процедури получавате 1 безплатно', 'При закупуване на 10 процедури получавате 2 безплатно'
        ],
    },
    {
        name: 'preshleni',
        price: '70 лв.',
        imageUrl: '/img/theraphy/preshleni.jpg',
        shortDescription: 'Тракционна терапия на шия',
        descriptions: [
            'АПАРАТ ЗА ТРАКЦИОННА ТЕРАПИЯ НА ШИЯ',
            'Уморихте ли се да страдате от болки във врата и скованост?',
            'Търсили ли сте решение, което може да осигури облекчение и да подобри качеството ви на живот?',
            'Апарата за тракционна терапия на шия е иновативно и ефективно устройство, което  спомога  за облекчаване на болката и подобряване на физическото благосъстояние.',
            'Съчетава  интегрирана функция от интелигентен електрически импулс, загряване, интелигентна въздушна тяга и  магнитен ефект.',
            'Осигурява  комфортно състояние на вашите цервикални прешлени и  коригира  правилната му  поза.',
            'Проектиран и произведен от Alphay Medical, апарата е  изработен с прецизност и грижа, за да осигури оптимални резултати. Отличава се с уникален дизайн, който съчетава сцепление, масаж, топлинна терапия и електрическа мускулна стимулация, създавайки мощен и многофункционален ефект.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Шийни прешлени',
            'РЕШЕНИЯ НА ПРОБЛЕМИ:',
            '1. Осигурява незабавно облекчение от болки във врата и скованост',
            '2. Подобрява кръвообращението, намалява възпалението и насърчава заздравяването',
            '3. Подобрява стойката, намалявайки натиска върху врата и гръбначния стълб',
            '4. Освобождава напрежението в мускулите, насърчавайки релаксацията и спокойствието',
            '5. Повишава енергийните нива, намалявайки умората и насърчавайки жизнеността',
            '6. Подобрява цялостното физическо и емоционално благосъстояние',
            'ТРЕТИРА УСПЕШНО:',
            '1. Болки и скованост в шията',
            '2. Болести на шийни прешлшни',
            '3. Възпаления или ревматични заболявания',
            '4. Болки в главата в следствие на увреждания на шийни прешлени',
            '5. Проблеми със зрението в следствие на нарушение на шийни прешлшни'
        ],
        faq: [
            'Как работи?',
            'Апарата  нежно разтяга мускулите на врата, създава пространство между прешлените и намалява натиска върху нервите. Той също така разполага с функция за масаж, която използва малки възли за оказване на натиск върху мускулите, осигурявайки лечебен, успокояващ и релаксиращ ефект. Освен това има вградена функция за топлинна терапия, която доставя инфрачервени лъчи към шията, насърчавайки оздравяването и намалявайки възпалението.',
            'Какви са предимствата?',
            'Традиционното устройство за статична цервикална тракция може да фиксира само шийния отдел на гръбначния стълб за тяга. Устройството за издърпване на шийните прешлени от трето поколение (с динамична въздушна възглавница), базирано на хипертермия, отпуска компресирания шиен прешлен чрез възвратно-постъпателно повдигане нагоре и надолу, упражнява шийния прешлен и подобрява симптомите на скованост на врата.',
            'Каква е препоръчителната честота?',
            '1 – 2 пъти седмично, като продължителността на процедурата е около 20-30 минути.',
            'Кога не се прилага процедурата?',
            'При онкологични заболявания, oстри инфекциозни заболявания и пейсмейкър на сърцето.'
        ],
        prices: [
            'Цена за 1 процедура - 70 лева'
        ],
    },
    {
        name: 'pelvic-therapy',
        price: '150 лв.',
        imageUrl: '/img/theraphy/pelvic.jpg',
        shortDescription: 'Апаратни рехабилитации на тазово дъно',
        descriptions: [
            'Апаратните рехабилитации на тазовото дъно са лечебени методи, използвани за укрепване на мускулите на тазовото дъно, повишаване на тяхната гъвкавост и подобряване на тяхната функция.',
            'Тези мускули изпълняват важни задачи като контрол на урината и изпражненията, подобряват сексуалните функции, подобряват, стягат и поддържат тазовите органи. ',
            'Рехабилитационният процес включва специални терапии, които се провеждат с най-висок клас апарати и технологии.',
            'Лечението е персонализирано според индивидуалните нужди и обикновено продължава 6-12 седмици, с две или три  сесии седмично.',
            'Процедурите се провеждат комплексно с Лазери, Акустични вълни и Апарати за Кегел терапии.',

        ]
        ,
        faq: [
            'За кого за предназначени апаратните рехабилитации на тазово дъно?',
            'Апаратните рехабилитации на тазово дъно са подходящи за хора с различни здравословни проблеми – жени и мъже. След раждане, след операции, уринарна инконтиненция, болки в таза, пролапс на тазовите органи и други. Високо ефективни методи за лечение на мъже и жени със сексуална дисфункция и синдром на хронична тазова болка.',
            'При какви здравословни проблеми помагат апаратните рехабилитации на тазово дъно?',
            'Инконтиненция на урина (стрес, неотложност, смесен тип). Пролапс на тазовите органи. Хронична тазова болка. Проблеми с тазовото дъно след раждане. Запек и проблеми с контрола на червата.',
            'Болезнени ли са апаратнатните рехабилитации на тазовото дъно?',
            'Апаратнатните рехабилитации на тазово дъно обикновено са безболезнени. Въпреки това, някои от тях в началото, могат да причинят лек дискомфорт. Като се има впредвид, че прагът на болка при всеки човек е различен, терапиите се провеждат съгласно персонализиран план.',
            'Безопасни ли са апаратните рехабилитации  на тазово дъно?',
            'Да, безопасни са. Когато се провеждат от квалифициран персонал, рисковете са минимални, изцяло съобразени с моментното състояние и нужди на пациента. При определени здравословни проблеми или бременност, се провежда предварителна консултация с лекар.',
            'Как се провеждат апаратните рехабилитации на тазово дъно?',
            'Процедурите са  различни от обикновените терапии. След установяване на проблема и моментното съсътояние се съставя индивидуален план, по който се работи. Сесиите могат да включват работа с един, два или три апарата.Броят на необходимите сесии варира и всяка сесия е персонализирана. Процедурите се провеждат в комфортна обстановка с внимание и професионализъм към всеки детайл.',
            'Необходимо ли е да се правят допълнителни упражнения у дома?',
            'Ние ще Ви препоръчаме и ще Ви дадем насоки за  да правите упражнения за тазово дъно сами у дома, но не е задължително. Упражненията, които правите у дома, подобряват ефекта. ',
        ],
        prices: [
            'От 150.00 до 250.00 лева за 1 сесия.'
        ],
        packetPrices: [
            'Пакетна цена с до 30% отстъпка.'
        ],
    },
];

export { therapiesData };